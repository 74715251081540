/* src/index.css */
@import "~bootstrap/dist/css/bootstrap.css";

* {
  padding: 0;
  margin: 0;
  box-sizing: border-box;
}

.left-side-container .static-data .sub-heading {
  color: #464646;
  font-weight: 600;
}

@media (max-width: 767px) {
  body {
    background-image: linear-gradient(
      45deg,
      rgba(12, 12, 12, 0.705),
      rgb(3, 43, 84)
    );

    background-repeat: no-repeat;
    background-size: 100% 100%;
    background-position: center;
    background-attachment: fixed;
    color: white;
  }
}
.left-side-container .heading-text {
  color: #404040;
  font-weight: 600;
  font-size: 30px;
  margin-top: 2%;
}

.left-side-container .static-data {
  padding-left: 10%;
}

/* .left-side-container .static-data .logo-container-computer {
  width: 75% !important;
} */

/* .left-side-container .static-data .logo-container-computer img {
  width: 85% !important;
} */

.left-side-container .static-data .sub-heading {
  font-size: 18px;
}

.paragraph {
  color: #404040;
  font-size: 16px;
  font-weight: 400;
  line-height: 1;
}
@media (max-width: 767px) {
  .left-side-container .static-data {
    display: none;
  }
}

@media (min-width: 768px) {
  body {
    background-image: url(assets/backgroundV2.svg) !important;
    background-repeat: repeat;
    background-size: 100% 100%;
    background-position: center;
    background-attachment: fixed;
    background-size: cover !important;
    color: white;
  }
}
@media screen and (min-width: 768px) and (max-width: 1023px) {
  /* .left-side-container .static-data .logo-container-computer {
    width: 95% !important;
  } */

  /* .left-side-container .static-data .logo-container-computer img {
    width: 100% !important;
  } */

  .left-side-container .static-data {
    padding-left: 1%;
  }

  .left-side-container .heading-text {
    font-size: 25px;
    margin-top: 2%;
  }

  .left-side-container .static-data .sub-heading {
    font-size: 15px;
    margin-bottom: 6%;
  }
  .left-side-container .static-data .paragraph {
    font-size: 14px;
  }
  /* .left-side-container .static-data .logo-container-computer img {
    margin-top: 20px;
  } */
}

@media screen and (min-width: 1023px) and (max-width: 1439px) {
  /* .left-side-container .static-data .logo-container-computer {
    width: 90% !important;
  } */

  /* .left-side-container .static-data .logo-container-computer img {
    width: 100% !important;
    margin-top: 10% !important;
  } */
  .left-side-container .static-data {
    padding-left: 4%;
  }
  .left-side-container .heading-text {
    margin-top: 1% !important;
    font-size: 30px;
  }
  .left-side-container .static-data .sub-heading {
    font-size: 18px;
  }
  .left-side-container .static-data .paragraph {
    font-size: 15px;
  }
}
@media screen and (min-width: 1440px) and (max-width: 1600px) {
  .left-side-container {
    margin: auto;
    height: auto;
  }
  .left-side-container .heading-text {
    font-size: 40px;
  }
  .left-side-container .static-data .sub-heading {
    font-size: 22px;
  }
  .paragraph {
    font-size: 18px;
  }
  /* .left-side-container .static-data .logo-container-computer {
    width: 90% !important;
    margin-top: 50px;
  } */
  /* .left-side-container .static-data .logo-container-computer img {
    width: 400px !important;
  } */
  .client-signup-container .client-container-bottom-text p {
    font-size: 14px;
  }
}
@media (min-width: 2000px) {
  /* .left-side-container .static-data .logo-container-computer img {
    width: 80% !important;
  } */
  .left-side-container .static-data .heading-text {
    font-size: 45px;
  }
  .left-side-container .static-data .sub-heading {
    font-size: 30px;
  }
  .left-side-container .static-data .paragraph {
    font-size: 28px;
  }
}

*:disabled {
  cursor: pointer;
}
