* {
    margin: 0;
    padding: 0;
}


.app-login-new-container {
    height: 100vh;

}

.app-login-new-container .fab-container {
    bottom: 0vh;
    position: fixed;
    margin: 1em;
    left: 2vw;
    right: 100vw;
}
.app-login-new-container .card-data-container{
     margin: auto;
     height:auto !important;
     width:80% !important;
     padding: 20px;
    }
    @media (max-width: 767px) {
        .app-login-new-container{
            margin-top: 15px;

            
        }
        .app-login-new-container .card-data-container{
            margin: auto;
            height:auto !important;
            width:100% !important;
          
           }
    }
    @media (min-width: 768px) and (max-width: 1023px) {
        .app-login-new-container .card-data-container{
            height: auto !important;
            width: 100% !important;
            padding: 10px 20px 10px 20px
        }
    }
    @media (min-width: 1024px) and (max-width: 1439px) {
        .app-login-new-container .card-data-container{
            height: auto !important;
            width: 100% !important;
        }
    }   
