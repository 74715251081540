@import url("https://fonts.googleapis.com/css?family=Poppins");
.input-container {
  position: relative;
 
  
}

.floating-label {
  position: absolute;
  top: 10px;
  left: 15px;
  font-size: 12px;
  color: #555; /* Change the color as needed */
  transition: top 0.3s, font-size 0.3s;
}
.user-url:focus + .floating-label,
.user-url:not(:placeholder-shown) + .floating-label {
  top: -10px;
  font-size: 10px;
  
  color: #007bff; /* Change the color when focused or has value */
}

.client-url-container {
  width: 95%;
  margin: auto;
  background-color: transparent;
  border: none;
 
  text-align: center;
}

.client-url-container {
  top: 22%;
}

.client-url-container .url-heading h2 {
  font-weight: 500;
  font-size: 25px;
  font-weight: 600;
  color: #262626;
}

.client-url-container .url-heading span {
  font-size: 22px;
  font-weight: 400;
}

.client-url-container .url-input {
  align-items: center;
  position: relative;
  margin: auto;
 
}

.client-url-container .url-input {
  text-align: center;
}
.client-url-container .url-input b {
  font-size: 18px;
}
.client-url-container .url-input input {
  border: 1.5px solid white;
  height: 40px;
  width:100%;
  border-radius: 20px;
  background-color: transparent;
  border: 1px solid #8C8C8C;
  font-size: 18px;
  color: black;
}

.client-url-container .url-input ::placeholder {
  color: white;
  font-size: 18px;
}
.client-url-container .url-input .candidate{
  margin-top: 2%;
}
.client-url-container .url-input b {
  font-weight: 400;

  align-self: self-start;
}
.client-url-container .url-input span {
  font-size: 18px;
  font-weight: 400;
  
}

.client-url-container .sign-btn .btn {
  height: 40px;
  background-color: #F36626;
  font-weight: 700;
  width: 100%;
  color: white !important;
  border-radius: 12px;
  font-size: 18px;
  border: none;
}
.client-url-container .url-input .url-notes {
  display: flex;
  flex-direction: column;
  margin:  auto;
  overflow: hidden;
  border-radius:10px;
  font-size: 30px;
  width: 70%;
  align-items: center;
  

  }


@media (max-width: 321px) {
  .client-url-container {
    width: 100%;
  }
  .client-url-container .url-heading h2 {
    font-size: 20px;
  }

  .client-url-container .url-heading span {
    font-size: 15px;
  }

  .client-url-container .url-input span {
    font-size: 11px !important;
    font-weight: 400;
  }

  .client-url-container .url-input input {
   height: 35px;
  }

  .client-url-container .sign-btn .btn {
    font-size: 18px;
    height: 35px;
  }
  .client-url-container .url-input .url-notes{
    font-size: 18px;
    text-align: left;
    margin:  auto;
    overflow: hidden;
    border-radius:10px;
    width: 100%;
    
  }
  .client-url-container .url-input .url-notes small{
    font-size: 16px;
    color: #283E9C !important; 

  }
}

@media (min-width: 322px) and (max-width: 376px) {
  .client-url-container {
    width: 100%;
  }
  .client-url-container .url-heading h2 {
    font-size: 20px;
  }

  .client-url-container .url-heading span {
    font-size: 18px;
  }

  .client-url-container .url-input span {
    font-size: 11px !important;
  }

  .client-url-container .url-input input {  
    height: 35px;
   
  }

  .client-url-container .sign-btn .btn {
    height: 35px;
  }
  .client-url-container .url-input .url-notes{
    font-size: 18px;
    text-align: left;
    margin:  auto;
    overflow: hidden;
    border-radius:10px;
    width: 100%;
  }
  .client-url-container .url-input .url-notes small{
    font-size: 18px;

  }

}

@media (min-width: 377px) and (max-width: 767px) {
  .client-url-container {
    width: 100%;
  }

  .client-url-container .url-heading h2 {
    font-size: 22px;
  }

  .client-url-container .url-heading span {
    font-size: 20px;
  }

  .client-url-container .url-input span {
    font-size: 17px;
  }

  .client-url-container .url-input input {
    height: 40px;
    padding-left:15px  !important;
    font-size: 16px;

  }

  .client-url-container .sign-btn .btn {
    height: 40px;
  }
  .client-url-container .url-input .url-notes{
    font-size: 18px;
    text-align: left;
    margin:  auto;
    overflow: hidden;
    border-radius:10px;
    width: 100%;
  }
  .client-url-container .url-input .url-notes small{
    font-size: 18px;
  }
  .client-url-container .url-input span {
    font-size: 13px !important;
}

  
}

@media (min-width: 768px) and (max-width: 1023px) {
 

  .client-url-container {
    width: 100
  }

  .client-url-container .url-heading h2 {
    font-size: 18px;
  }

  .client-url-container .url-heading span {
    font-size: 16px;
  }

  .client-url-container .url-input span {
    font-size: 12px !important;
  }

  .client-url-container .url-input input {
    height: 35px;

    font-size: 18px;
  }

  .client-url-container .sign-btn .btn {
    height: 35px;
    font-size: 16px;
  }
  .client-url-container .url-input .url-notes{
    font-size: 18px;
    text-align: left;
    margin:  auto;
    overflow: hidden;
    border-radius:10px;
    width: 100%;
  }
  .client-url-container .url-input .url-notes small{
    font-size: 18px;

  }
}



@media (min-width: 1024px) and (max-width: 1199px) {
  .client-url-container {
    width: 100%;
  }

  .client-url-container .url-heading h2 {
    font-size: 25px;
  }

  .client-url-container .url-heading span {
    font-size: 19px;
  }

  .client-url-container .url-input input {
   height: 40px;

  }

  .client-url-container .url-input span {
    font-size: 15px !important;
  }

  .client-url-container .sign-btn .btn {
    height: 40px;
    width: 100%;
    
  }
  .client-url-container .url-input .url-notes{
    font-size: 18px;
    text-align: left;
    margin:  auto;
    overflow: hidden;
    border-radius:10px;
    width: 90%;
  }
  .client-url-container .url-input .url-notes small{
    font-size: 20px;

  }
}

@media (min-width: 1200px) {
  .client-url-container .url-heading h2 {
    font-size: 25px;
  }

  .client-url-container .url-heading span {
    font-size: 22px;
  }

  .client-url-container .url-input input {
    position: relative;
    margin: auto;
   
  }

  .client-url-container .url-input span {
    font-size: 15px ;
  }

  .client-url-container .sign-btn .btn {
    height: 40px;
    
  }
  .client-url-container .url-input .url-notes{
    font-size: 18px;
    text-align: left;
    margin:  auto;
    overflow: hidden;
    border-radius:10px;
    width: 90%;
  }
  .client-url-container .url-input .url-notes small{
    font-size: 22px;

  }
}

@media (min-width: 2550px) {
  .client-url-container .url-input input {
    position: relative;
    margin: auto;
    width: 60%;
  }

  .client-url-container .url-input span {
    font-size: 20px !important;
  }

  .client-url-container .sign-btn .btn {
    height: 40px;
    width: 30%;
  }
  .client-url-container .url-input .url-notes{
    font-size: 18px;
    text-align: left;
    margin:  auto;
    overflow: hidden;
    border-radius:10px;
    width: 60%;
  }
  .client-url-container .url-input .url-notes small{
    font-size: 22px;

  }
}
