/* combine css for all templates fisrt container */

@import url("https://fonts.googleapis.com/css?family=Poppins");

* {
  margin: 0;
  padding: 0;
  font-family: poppins;
}

.app-login-new-container {
  width: 85%;
  margin: auto auto;
  max-height: 100vh;
}

.app-login-new-container .welcome-container {
  color: black;
  border-radius: 12px;
  height: 95%;
  width: 100%;
  margin: auto;
  text-align: center;
}

.app-login-new-container .welcome-container .blink-logo img {
  width: 40%;
  /* float: left; */
}

.app-login-new-container .welcome-heading {
  text-align: center;
}

/* .app-login-new-container .welcome-heading h1 {
  font-weight: 700;
  font-size: 35px;
} */

.app-login-new-container .welcome-heading p {
  font-weight: 450;
  font-size: 21px;
  margin: 0;

}

.app-login-new-container .slider-heading {
  font-weight: 700;
  font-size: 35px;
}

@media (max-width: 767px) {
  body {

    background-repeat: no-repeat;
    background-size: 100% 100%;
    background-position: center;
    background-attachment: fixed;
    color: white;
  }
}

@media (min-width: 768px) {
  body {
    background-image: linear-gradient(45deg,
        rgba(12, 12, 12, 0.705),
        rgb(3, 43, 84));

    background-repeat: no-repeat;
    background-size: 100% 100%;
    background-position: center;
    background-attachment: fixed;
    color: white;
  }
}

.app-login-new-container .welcome-container {
  color: black;
  border-radius: 12px;
  height: 95%;
  width: 100%;
  margin: auto;
  text-align: center;
}

@media (min-width: 768px) and (max-width: 1023px) {
  /* .app-login-new-container .welcome-container {
    height: 100%;
    width: 100%;
    margin: auto;
  } */

  .app-login-new-container .welcome-container .welcome-heading h1 {
    font-size: 30px;
    margin-top: 30px;
  }

  .app-login-new-container .welcome-container .welcome-heading p {
    font-size: 17px;
    margin-top: 20px;
  }

  .welcome-container .blink-logo img {
    width: 25%;
  }

  .welcome-container .computer-img {
    margin-top: 50px;
  }
}

@media (min-width: 1024px) and (max-width: 1440px) {
  /* .app-login-new-container .welcome-container {
    width: 90%;
    height: 95vh;
  } */

  .app-login-new-container .welcome-heading h1 {
    font-size: 35px;
  }

  .app-login-new-container .welcome-container .welcome-heading p {
    font-size: 20px;
  }

  .welcome-container .blink-logo img {
    width: 25%;
  }

  .welcome-container .computer-img {
    margin-top: 50px;
  }
}

@media (min-width: 1440px) and (max-width: 2000px) {
  /* .app-login-new-container .welcome-container {
    height: 95vh;
    width: 80%;
  } */

  .app-login-new-container .welcome-heading h1 {
    font-size: 40px;
  }

  .app-login-new-container .welcome-container .welcome-heading p {
    font-size: 22px;
  }

  .welcome-container .blink-logo img {
    width: 25%;
  }

  .welcome-container .computer-img {
    margin-top: 30px;
  }
}

@media (min-width: 1800px) {
  /* .app-login-new-container .welcome-container {
    height: 100%;
    width: 80%;
  } */

  .app-login-new-container .welcome-heading h1 {
    font-size: 40px;
  }

  .app-login-new-container .welcome-container .welcome-heading p {
    font-size: 22px;
  }

  .welcome-container .blink-logo img {
    width: 20%;
  }
}