@import url("https://fonts.googleapis.com/css?family=Poppins");

.client-otp-container  {
    width: 95%;
    margin: auto;
    background-color: transparent;
    border: none;
    color: black;
    text-align: center;
    

}

.resend-btn-clicked {
    font-size: 16px;
    color: red; /* Adjust the font size as needed */
  }
.client-otp-heading h4 {
    font-weight: 500;
    font-size: 25px;
}
 
.client-otp-heading span {
    font-weight: 400;
    font-size: 19px;
}

.otp input {
    width: 42px;
    height: 42px;
    background-color: transparent;
    border: 2px solid #999;
    margin: 4px;
    font-size: 25px;
    color: black;
    outline: none;
    text-align: center;
    margin-top: 20px;
}

input::-webkit-outer-spin-button,
input::-webkit-inner-spin-button {
    -webkit-appearance: none;
    margin: 0;
}
.client-otp-container .resend-otp a{
    color:#283E9C;
    font-size: 18px;
}
.buttons button{

    font-weight: 700;
    font-size: 18px;
    width: 40%;
    height: 45px;
    color: white;
    background-color: #283E9C;
    border-radius: 8px;
    border: none;
   
}  
.resend-otp{
    text-align: center;
}
.resend-btn{
    background: transparent;
    border: none;
    color: white;
   
    
}
@media (max-width:376px){
    .client-otp-container{
        width: 90%;
    }
 
    .client-otp-heading h4 {
        font-size: 17px;
    }

    .client-otp-heading span {
        font-size: 14px;
    }
    .otp input {
        width: 35px;
        height: 35px;
    }
   
    
    .buttons .submit {
        font-size: 18px;
        width: 80%;
        height: 35px;   
    }
} 
@media (min-width:377px) and (max-width:767px){
    .client-otp-container{
        width: 95%;
    }



    .client-otp-heading h4 {
        font-size: 22px;
    }

    .client-otp-heading span {
        font-size: 17px;
    }
    .otp input {
        width: 40px;
        height: 40px;
       
    }
   
    .buttons .submit {
        font-size: 16px;
        width: 80%;

    }
} 
@media (min-width: 768px) and (max-width: 1023px) {
   
    .client-otp-container {
        width: 100%;
    }
    .client-otp-container  {
        position: relative;

        top: 24%;
    }

    .client-otp-heading h4 {
        font-size: 20px;
    }

    .client-otp-heading span {
        font-size: 15px;
    }

    .otp input {
        width: 36px;
        height: 36px;
    }
  
    .buttons .submit {
        font-size: 22px;
        width: 50%;
        height: 45px;

    }
}

@media (min-width: 1024px) and (max-width:1439px) {
      
    .client-otp-container  {
        position:relaive;
        top: 24%;
    }


 
    .client-otp-heading h4 {
        font-size: 24px;
    }

    .client-otp-heading span {
        font-size: 18px;
    }
  
    .otp input {
        width: 38px;
        height: 38px;
    }
    .buttons .submit {
        font-size: 23px;
        width: 60%;
        height: 45px;
    }

}

@media (min-width:1440px) and (max-width:1999px){
    
    .client-otp-container{
        width: 90%;
    }

    .client-otp-container  {
    
    top: 24%;
    width: 95%;
    }
 
    
    .client-otp-heading h4 {
        font-size: 25px;
    }
     
    .client-otp-heading span {
        font-size: 20px;
    }
    .otp input {
        width: 50px;
        height: 50px;
    }
   
    .client-otp-container .resend-otp a{
        font-size: 23px;
    }
    .buttons .submit {
        font-size: 20px;
        width: 45%;
        height: 45px;
    }
} 
@media (min-width:2000px){

    
    .client-otp-heading h4 {
        font-size: 37px;
    }
     
    .client-otp-heading span {
        font-size: 28px;
    }
    .otp input {
        width: 50px;
        height: 50px;   
    }
    
    .client-otp-container .resend-otp a{
        font-size: 18px;
    }
    .buttons button{
        font-size: 22px;
        width: 40%;
        height: 45px;
        
    } 
}
