@import url("https://fonts.googleapis.com/css?family=Poppins");

.form-group {
  position: relative;

}

label {
  position: absolute;
  top: 45%;
  left: 8px;
  transform: translateY(-40%);
  transition: transform 0.5s ease-out, font-size 0.5s ease-out;
  pointer-events: none;
  font-size: 16px;
  color: #999;
  padding: 7px;
  padding-left: 10px;
  background-color: white;
  border-radius: 4px;
  display: inline-block;
}

.floating-label {
  transform: translateY(-90%) scale(0.9);
  font-size: 16px !important;
  color: #283E9C !important;
  left: 8px !important;
  display: inline-block;
}

.input-icon-container {
  position: relative;
}

.form-group .phone-input {
  width: '100%';
  padding-Left: 16%
}

.eye-icon {
  position: absolute;
  top: 50%;
  right: 10px;
  transform: translateY(-50%);
  cursor: pointer;
  font-size: 22px !important;
}



.otp-Choice-container  .heading {
  font-size: 14px;
}

.radioGroup {
  display: flex;
}

 .radioButton {
  display: flex ;
  flex-Direction: row ;
  align-Items: center ;
}

 .radioLabel {
  margin-Left: 8px;
  font-Size: 13px;
  color: #333;
  margin-right: 20px;
  margin-bottom: 0px;
}



.client-signup-container {
  min-height: 70%;
  width: 85%;
  margin: auto;
  background-color: transparent;
  border: none;
}

.client-signup-container {
  position: relative;
  /* top: 10%; */
}

.client-signup-container .sign-up {
  background-color: transparent;
  border: 1.5px solid white;
  color: white;
}



.client-container-heading h3 {
  font-size: 30px;
  font-weight: 700;
  /* color: white; */
}

.client-signup-container .client-container-text p {
  font-size: 13px;
  font-weight: 400;
  color: #6c6c6c;
}

.client-signup-container .client-container-bottom-text p {
  font-size: 12px;
  font-weight: 400;
  color: #262626;
  text-align: justify;
}



.client-signup-container .Signup {
  background-color: #F36626;
  font-weight: 600;
  border-radius: 8px;

  font-size: 15px;
  height: 40px;
  color: white;
  border: none;
  width: '100%'

}

.client-signup-container .form-group input::-webkit-outer-spin-button,
input::-webkit-inner-spin-button {
  -webkit-appearance: none;
  margin: 0;
}

.client-signup-container .client-pass {
  width: 100%;
  /* border: 1.5px solid white; */
  border-radius: 8px;
}

.client-signup-container .form-group label {
  font-size: 14px;
}

.client-signup-container .form-group input {
  background-color: transparent;
  /* color: white; */
  font-weight: 400;
  /* border: 1 solid white; */
  border-radius: 8px;
  height: 40px;
  width: 100%;
  outline: none;
  padding-top: 11px;
  padding-left: 10px;



}

.client-signup-container .form-group input:focus {
  background-color: transparent;
  /* color: white; */
  box-shadow: none;
  /* border: 1.5px solid white; */

}

.client-signup-container .client-pass input:focus {
  border: 1px solid gray;
}

.client-signup-container .form-group .select-menu {
  width: 100%;
  height: 40px;
  border-radius: 8px;
  background-color: transparent;
  /* border: 1.5px solid black; */
  color: gray;
  font-weight: 400;
  outline: none;
  font-size: 14px;
}

.client-signup-container .form-group option {
  background-color: transparent;
  color: gray;
  width: 50%;
  font-size: 14px;
}

small {
  color: red;
  font-size: smaller;
}

.terms-condition {
  color: #fff;
}

.eye-icon {
  font-size: 15px;

  /* color: #fff; */

}

.terms-check {
  display: flex;
  position: relative;
  gap: 5px;

}

.terms-check .text-field {
  font-size: 14px;
}

.inp-check {
  width: 25px;
}




@media (max-width: 676px) {
  .client-signup-container {
    width: 100%;
    margin: auto;
    background-color: transparent;
    border: none;
  }

  .client-signup-container h3 {
    font-size: 18px;
    text-align: center;
  }

  .client-signup-container .blink img {
    width: 35%;
  }

  .sign-up,
  .log-in {
    display: none;
  }

  .form-group {
    margin: 0px !important;
  }

  .client-signup-container .first {
    margin-bottom: 3%;
  }

  .client-signup-container .email {
    margin-bottom: 3%;
  }

  .client-signup-container .password {
    margin-bottom: 3%;
  }

  .client-signup-container .form-group input {
    margin-top: 10px !important;
    font-size: 12px;

  }

  .floating-label {
    top: 10px;
  }

  .terms-check .text-field {
    font-size: 10px;
  }

  .client-signup-container .client-container-bottom-text p {
    font-size: 8px !important;
  }

  .client-signup-container .client-container-text p {
    font-size: 9px !important;
    text-align: center;
  }

  .client-signup-container .form-group label {
    font-size: 10px;
  }

  .client-signup-container .form-group input {
    height: 35px;

  }

  .form-group .first {
    margin-top: -10px !important;
  }

  .client-signup-container .form-group .select-menu {
    height: 35px;
    font-size: 10px;
  }

  .client-signup-container {
    margin-top: -10px;
  }

  .client-signup-container .Signup {
    height: 35px;
  }
  /* .organization{
    width: 90% !important;
    height: 35px !important;
    margin-left: 5% !important;
    font-size: 10px !important;
  }
  .student{
    width: 90% !important;
    height: 35px !important;
    margin-left: 5% !important;
    font-size: 10px !important;
    margin-top: 5% !important;
  } */
  .organizationText{
    font-size: 12px !important;
  }
  .restrictedText{
    font-size: 9px !important;
    padding: 10px !important;

  }
  .restrictedImg{
    height: 200px;
    width: 200px;
  }
}

@media screen and (min-width: 374px) and (max-width: 423px) {
  .form-group .phone-input {
    padding-left: 13% !important;
  }
}

@media screen and (min-width: 424px) and (max-width: 767px) {
  .form-group .phone-input {
    padding-left: 11% !important;
  }
}

@media (max-width:373px) {
  .form-group .phone-input {

    padding-Left: 15% !important
  }
}



@media (min-width: 768px) {
  .client-signup-container .blink {
    display: none;

  }
}

@media (min-width: 768px) and (max-width: 1023px) {
  .client-signup-container {
    width: 100%;
  }

  .client-signup-container .client-container-heading {
    margin-top: 0px !important;
  }

  .client-signup-container .client-container-heading h3 {
    font-size: 18px;
  }

  label {
    left: 8px;
  }

  .client-signup-container .client-container-text p {
    font-size: 10px;

  }

  .terms-check .text-field {
    font-size: 10px;
  }

  .client-signup-container .form-group .select-menu {
    font-size: 13px;
    height: 35px;
  }

  .client-signup-container .form-group label {
    font-size: 13px !important;
  }

  .client-signup-container .Signup {
    height: 35px;
  }

  .client-signup-container .form-group input {
    height: 35px;
    font-size: 12px;
  }

  .form-group .phone-input {
    padding-Left: 27% !important
  }
  .organizationText{
    font-size: 10px !important;
  }
  .restrictedText{
    font-size: 11px !important;
    padding: 10px !important;

  }
  .orgRadioButton{
    height: 35px !important;
  }

}

@media (min-width: 1024px) and (max-width: 1439px) {
  .client-signup-container {
    width: 100%;

  }

  .client-signup-container .form-group input {

    font-size: 12px !important;

  }

  .client-signup-container .form-group label {
    font-size: 15px;
  }

  .client-container-heading h3 {
    font-size: 18px;
  }

  .client-signup-container .client-container-text p {
    font-size: 11px;

  }

  .client-signup-container .client-container-heading {
    margin-top: 0rem !important;
  }

  .terms-check .text-field {
    font-size: 12px;
  }

  .client-signup-container .client-container-bottom-text p {
    font-size: 9px;
  }

  .client-signup-container .sign-up,
  .client-signup-container .log-in {
    width: 105px;
    font-size: 18px;
    padding: 4px;
  }

  label {
    font-size: 12px !important;
  }

  .floating-label {
    font-size: 16px !important;
  }

  .client-signup-container .form-group .select-menu {
    font-size: 12px;
  }

  .form-group .phone-input {
    padding-Left: 21% !important
  }
  .organizationText{
    font-size: 12px !important;
  }
}


@media (min-width: 1439px) {
  .client-signup-container {
    width: 100% !important;
  }

  .client-container-heading h3 {
    font-size: 22px;
    /* text-align: center; */
  }

  .client-signup-container .client-container-heading {
    margin-top: 0rem !important;
  }

  .client-signup-container .client-container-text p {
    font-size: 14px;
  }

  .client-signup-container .form-group .select-menu {
    font-size: 14px;
  }

  .form-group .phone-input {
    padding-Left: 18% !important
  }

}

@media (min-width: 2000px) {
  .form-group .phone-input {
    padding-Left: 10% !important
  }
  .client-container-heading h3 {
    font-size: 30px;
  }
  .client-signup-container .client-container-text p {
    font-size: 22px;
}
  .app-login-new-container .card-data-container {
    margin: auto;
    padding: 40px;
    width: 100% !important;
  }
  .client-signup-container .form-group label {
    font-size: 18px !important;
}
.client-signup-container .form-group .select-menu {
  font-size: 18px;
}
.client-signup-container .client-container-bottom-text p {
  font-size: 14px;}
  .terms-check .text-field {
    font-size: 16px;
}
.otp-Choice-container .heading {
  font-size: 18px;
}
.radioLabel {
  font-size: 16px;
}
.client-signup-container .Signup{
  font-size: 20px;
}
.organizationText{
  font-size: 15px !important;
}
  
}