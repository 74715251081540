.client-loading-bar-container  {
    position: relative;
    /*  property change from absolte to relative */
    top: 28%;
}

 .client-loading-bar-container{
    width: 80%;
    height: 60%;
    margin: auto;
    border: none;
    background-color: transparent;
    color: black;
    text-align: center;
}
 .client-loading-bar-container .loading-bar-heading h2{
    font-size: 32px;
    font-weight: 600;
}
 .client-loading-bar-container .loading-bar-heading span{
    font-size: 20px;
    color: black !important;
    font-weight: 400;
}
 .client-loading-bar-container .loading-bar progress {
    width:58%;
    height:35px;
    border-radius: 4px;
    border: 2px solid #ffffff;
    margin-top: 15px;
    text-align: center;
}
progress::-webkit-progress-bar {
    background-color: transparent;
}
progress::-webkit-progress-value {
    /* background-color: #ffcc00; */
    background-color: #283E9C;
    border-radius: 4px;
}
 .client-loading-bar-container .percentage-value {
    font-size: 20px;
    font-weight: 500;
}

 .client-loading-bar-container .loading-paragraph {
    font-size: 20px;
    font-weight: 700;
}


@media (max-width:376px){
     .client-loading-bar-container {
        width: 95%;
        height: 80%;
    }
     .client-loading-bar-container .blink-logo{
        text-align: left;
    }
     .client-loading-bar-container .loading-bar-heading h2{
        font-size: 20px;
    }

    .client-loading-bar-container .loading-bar-heading span{
        font-size: 18px;
    }
    .client-loading-bar-container .blink-logo img{
        width: 30%;
    }
    .client-loading-bar-container .percentage-value {
        font-size: 14px;
    }
    .client-loading-bar-container .loading-paragraph {
        font-size: 14px;
    }
    .client-loading-bar-container .loading-bar progress {
        width: 95%;
        height: 30px;
    }
}
@media (min-width:377px) and (max-width:767px){
     .client-loading-bar-container {
        width: 95%;
        height: 80%;
    }
     .client-loading-bar-container .blink-logo{
        text-align: left;
    }
     .client-loading-bar-container .blink-logo img{
        width: 25%;
    }
    .client-loading-bar-container .loading-bar-heading h2{
        font-size: 24px;
    } 
    .client-loading-bar-container .loading-bar-heading span{
        font-size: 20px;
    }   
    .client-loading-bar-container .percentage-value {
        font-size: 16px;
    }
    .client-loading-bar-container .loading-paragraph {
        font-size: 16px;
    }
    .client-loading-bar-container .loading-bar progress {
        width: 90%;
        height: 30px;
    }
}
@media (min-width:768px){
     .client-loading-bar-container .blink-logo{
        display: none;
    }
}
@media (min-width:768px) and (max-width:1023px){
     .client-loading-bar-container  {
        position: relative;
        top: 15%;
    }
     .client-loading-bar-container {
        width: 90%;
        height: 60%;
        margin-left: 30px;
    }    
     .client-loading-bar-container .loading-bar-heading h2{
        font-size: 27px;
    }

     .client-loading-bar-container .loading-bar-heading span{
        font-size: 17px;
        color: #EEEEEE;
    }

     .client-loading-bar-container .loading-bar progress {
        width:100%;
        height:30px;
        border-radius: 4px;
        border: 2px solid #ffffff;
        padding: 2px;
    }

     .client-loading-bar-container .loading-paragraph {
        font-size: 15px;
    }
    
     .client-loading-bar-container .percentage-value {
        font-size: 16px;
    }
}
@media (min-width:1024px) and (max-width:1439px){

    .client-loading-bar-container{
        width: 95%;
        margin-left: 20px;
    }

    .client-loading-bar-container .loading-bar-heading h2{
       font-size: 29px;
    }

    .client-loading-bar-container .loading-bar-heading span{
        font-size: 18px;
        color: #EEEEEE;
    }

    .client-loading-bar-container .loading-bar progress {
        width:95%;
        height:30px;
        border-radius: 4px;
        border: 2px solid #ffffff;
        padding: 2px;
        margin-top: 40px;
    }

    .client-loading-bar-container .percentage-value {
        font-size: 18px;
        font-weight: 500;
    }
    .client-loading-bar-container .loading-paragraph {
        font-size: 20px;
    }
}
@media (min-width:1440px) and (max-width:1799px){

    .client-loading-bar-container{
        width: 95%;
    }

    .client-loading-bar-container .loading-bar-heading h2{
       font-size: 30px;
    }

    .client-loading-bar-container .loading-bar-heading span{
        font-size: 18px;
        
    }

    .client-loading-bar-container .loading-bar progress {
        width:75%;
        height:30px;
        border-radius: 4px;
        border: 2px solid #ffffff;
        padding: 2px;
        margin-top: 15px;
    }

    .client-loading-bar-container .percentage-value {
        font-size: 18px;
        font-weight: 500;
    }
    .client-loading-bar-container .loading-paragraph {
        font-size: 20px;
    }
}
/* @media (min-width:1800px){
    .client-loading-bar-container .loading-bar-heading h2{
        font-size: 30px;
     }
 
     .client-loading-bar-container .loading-bar-heading span{
         font-size: 25px;
         color: #EEEEEE;
     }
 
     .client-loading-bar-container .loading-bar progress {
         width:50%;
         height:30px;
         border-radius: 4px;
         border: 2px solid #ffffff;
         padding: 2px;
         margin-top: 40px;
     }
 
     .client-loading-bar-container .percentage-value {
         font-size: 22px;
         font-weight: 500;
     }
     .client-loading-bar-container .loading-paragraph {
         font-size: 22px;
     }
} */