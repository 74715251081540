@import url("https://fonts.googleapis.com/css?family=Poppins");
.form-group {
  position: relative;

}

label {
  position: absolute;
  top: 45%;
  left: 8px;
  transform: translateY(-40%);
  transition: transform 0.5s ease-out, font-size 0.5s ease-out;
  pointer-events: none;
  font-size: 16px;
  color: #999;
   padding: 7px; 
   padding-left: 10px;
   background-color: white;
   border-radius: 4px;
   display: inline-block;
}

.floating-label {
  transform: translateY(-90%) scale(0.9);
  font-size: 16px !important;
  color: #283E9C !important;
  left:8px !important;
  display: inline-block; 
}
.input-icon-container {
  position: relative;
}

.eye-icon {
  position: absolute;
  top: 50%;
  right: 10px;
  transform: translateY(-50%);
  cursor: pointer;
  font-size: 25px !important;
}

.client-signup-container {
  min-height: 80%;
  width: 80%;
  margin: auto;
  background-color: transparent;
  border: none;
}

.client-signup-container {
  position: relative;
  /* top: 10%; */
}

.client-signup-container .sign-up {
  background-color: transparent;
  border: 1.5px solid white;
  color: white;
}



.client-container-heading h3 {
  font-size: 30px;
  font-weight: 700;
  /* color: white; */
}



.client-signup-container .Signup {
  background-color: white;
  font-weight: 700;
  border-radius: 8px;
  font-size: 20px;
  height: 45px;

}

.client-signup-container .form-group input::-webkit-outer-spin-button,
input::-webkit-inner-spin-button {
  -webkit-appearance: none;
  margin: 0;
}

.client-signup-container .client-pass {
  width: 100%;
  /* border: 1.5px solid white; */
  border-radius: 8px;
}



.client-signup-container .form-group input {
  background-color: transparent;
  /* color: white; */
  font-weight: 400;
  /* border: 1 solid white; */
  border-radius: 8px;
  height: 45px;
  width: 100%;
  outline: none;
  padding-top: 11px;
  padding-left: 10px;
 

}

.client-signup-container .form-group input:focus {
  background-color: transparent;
  /* color: white; */
  box-shadow: none;
  /* border: 1.5px solid white; */

}

.client-signup-container .client-pass input:focus {
  border: 1px solid gray;
}

.client-signup-container .form-group .select-menu {
  width: 100%;
  height: 45px;
  border-radius: 8px;
  background-color: transparent;
  /* border: 1.5px solid black; */
  color: gray;
  font-weight: 400;
  outline: none;
}

.client-signup-container .form-group option {
  background-color: transparent;
  color: gray;
  width: 50%;
}

small {
  color: red;
  font-size: smaller;
}

.terms-condition {
  color: #fff;
}

.eye-icon {
  font-size: 15px;

  /* color: #fff; */

}

.terms-check {
  display: flex;
  position: relative;
  gap: 5px;

}

.inp-check {
  width: 25px;
}



@media (max-width: 676px) {
  .client-signup-container {
    width: 100%;
    margin: auto;
    background-color: transparent;
    border: none;
  }

  .client-signup-container h3 {
    font-size: 25px;
    text-align: center;
  }

  .client-signup-container .blink img {
    width: 35%;
  }

  .sign-up,
  .log-in {
    display: none;
  }

  .form-group {
    margin: 0px !important;
  }

  .client-signup-container .first {
    margin-bottom: 4%;
  }
  .client-signup-container .email {
    margin-bottom: 4%;
  }
  .client-signup-container .password {
    margin-bottom: 4%;
  }
  .client-signup-container .form-group input{
    margin-top: 10px;
  
  }
  .floating-label{
    top: 20px;
  }
}

@media (min-width: 768px) {
  .client-signup-container .blink {
    display: none;

  }
}

@media (min-width: 768px) and (max-width: 1023px) {
  .client-signup-container {
    width: 90%;
  }

  .client-signup-container .client-container-heading {
    margin-top: 0px !important;
  }

  .client-signup-container .client-container-heading h3 {
    font-size: 22px;
  }
  label {
    
    left: 1px;}

}

@media (min-width: 1024px) and (max-width: 1439px) {
  .client-signup-container {
    width: 90%;

  }

  .client-container-heading h3 {
    font-size: 25px;
  }

  .client-signup-container .client-container-heading {
    margin-top: 1rem !important;
  }

  .client-signup-container .sign-up,
  .client-signup-container .log-in {
    width: 105px;
    font-size: 18px;
    padding: 4px;
  }
}

@media (min-width: 1440px) {
  .client-signup-container {
    width: 90%;
  }

  .client-container-heading h3 {
    font-size: 35px;
  }

  .client-signup-container .client-container-heading {
    margin-top: 1rem !important;
  }
}