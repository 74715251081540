@import url(https://fonts.googleapis.com/css?family=Poppins);
@import url(https://fonts.googleapis.com/css?family=Poppins);
@import url(https://fonts.googleapis.com/css?family=Poppins);
@import url(https://fonts.googleapis.com/css?family=Poppins);
@import url(https://fonts.googleapis.com/css?family=Poppins);
@import url(https://fonts.googleapis.com/css?family=Poppins);
@import url(https://fonts.googleapis.com/css?family=Poppins);
/* src/index.css */

* {
  padding: 0;
  margin: 0;
  box-sizing: border-box;
}

.left-side-container .static-data .sub-heading {
  color: #464646;
  font-weight: 600;
}

@media (max-width: 767px) {
  body {
    background-image: linear-gradient(
      45deg,
      rgba(12, 12, 12, 0.705),
      rgb(3, 43, 84)
    );

    background-repeat: no-repeat;
    background-size: 100% 100%;
    background-position: center;
    background-attachment: fixed;
    color: white;
  }
}
.left-side-container .heading-text {
  color: #404040;
  font-weight: 600;
  font-size: 30px;
  margin-top: 2%;
}

.left-side-container .static-data {
  padding-left: 10%;
}

/* .left-side-container .static-data .logo-container-computer {
  width: 75% !important;
} */

/* .left-side-container .static-data .logo-container-computer img {
  width: 85% !important;
} */

.left-side-container .static-data .sub-heading {
  font-size: 18px;
}

.paragraph {
  color: #404040;
  font-size: 16px;
  font-weight: 400;
  line-height: 1;
}
@media (max-width: 767px) {
  .left-side-container .static-data {
    display: none;
  }
}

@media (min-width: 768px) {
  body {
    background-image: url(/static/media/backgroundV2.517b7d2f.svg) !important;
    background-repeat: repeat;
    background-size: 100% 100%;
    background-position: center;
    background-attachment: fixed;
    background-size: cover !important;
    color: white;
  }
}
@media screen and (min-width: 768px) and (max-width: 1023px) {
  /* .left-side-container .static-data .logo-container-computer {
    width: 95% !important;
  } */

  /* .left-side-container .static-data .logo-container-computer img {
    width: 100% !important;
  } */

  .left-side-container .static-data {
    padding-left: 1%;
  }

  .left-side-container .heading-text {
    font-size: 25px;
    margin-top: 2%;
  }

  .left-side-container .static-data .sub-heading {
    font-size: 15px;
    margin-bottom: 6%;
  }
  .left-side-container .static-data .paragraph {
    font-size: 14px;
  }
  /* .left-side-container .static-data .logo-container-computer img {
    margin-top: 20px;
  } */
}

@media screen and (min-width: 1023px) and (max-width: 1439px) {
  /* .left-side-container .static-data .logo-container-computer {
    width: 90% !important;
  } */

  /* .left-side-container .static-data .logo-container-computer img {
    width: 100% !important;
    margin-top: 10% !important;
  } */
  .left-side-container .static-data {
    padding-left: 4%;
  }
  .left-side-container .heading-text {
    margin-top: 1% !important;
    font-size: 30px;
  }
  .left-side-container .static-data .sub-heading {
    font-size: 18px;
  }
  .left-side-container .static-data .paragraph {
    font-size: 15px;
  }
}
@media screen and (min-width: 1440px) and (max-width: 1600px) {
  .left-side-container {
    margin: auto;
    height: auto;
  }
  .left-side-container .heading-text {
    font-size: 40px;
  }
  .left-side-container .static-data .sub-heading {
    font-size: 22px;
  }
  .paragraph {
    font-size: 18px;
  }
  /* .left-side-container .static-data .logo-container-computer {
    width: 90% !important;
    margin-top: 50px;
  } */
  /* .left-side-container .static-data .logo-container-computer img {
    width: 400px !important;
  } */
  .client-signup-container .client-container-bottom-text p {
    font-size: 14px;
  }
}
@media (min-width: 2000px) {
  /* .left-side-container .static-data .logo-container-computer img {
    width: 80% !important;
  } */
  .left-side-container .static-data .heading-text {
    font-size: 45px;
  }
  .left-side-container .static-data .sub-heading {
    font-size: 30px;
  }
  .left-side-container .static-data .paragraph {
    font-size: 28px;
  }
}

*:disabled {
  cursor: pointer;
}

.form-group {
  position: relative;

}

label {
  position: absolute;
  top: 45%;
  left: 8px;
  transform: translateY(-40%);
  transition: transform 0.5s ease-out, font-size 0.5s ease-out;
  pointer-events: none;
  font-size: 16px;
  color: #999;
   padding: 7px; 
   padding-left: 10px;
   background-color: white;
   border-radius: 4px;
   display: inline-block;
}

.floating-label {
  transform: translateY(-90%) scale(0.9);
  font-size: 16px !important;
  color: #283E9C !important;
  left:8px !important;
  display: inline-block; 
}
.input-icon-container {
  position: relative;
}

.eye-icon {
  position: absolute;
  top: 50%;
  right: 10px;
  transform: translateY(-50%);
  cursor: pointer;
  font-size: 25px !important;
}

.client-signup-container {
  min-height: 80%;
  width: 80%;
  margin: auto;
  background-color: transparent;
  border: none;
}

.client-signup-container {
  position: relative;
  /* top: 10%; */
}

.client-signup-container .sign-up {
  background-color: transparent;
  border: 1.5px solid white;
  color: white;
}



.client-container-heading h3 {
  font-size: 30px;
  font-weight: 700;
  /* color: white; */
}



.client-signup-container .Signup {
  background-color: white;
  font-weight: 700;
  border-radius: 8px;
  font-size: 20px;
  height: 45px;

}

.client-signup-container .form-group input::-webkit-outer-spin-button,
input::-webkit-inner-spin-button {
  -webkit-appearance: none;
  margin: 0;
}

.client-signup-container .client-pass {
  width: 100%;
  /* border: 1.5px solid white; */
  border-radius: 8px;
}



.client-signup-container .form-group input {
  background-color: transparent;
  /* color: white; */
  font-weight: 400;
  /* border: 1 solid white; */
  border-radius: 8px;
  height: 45px;
  width: 100%;
  outline: none;
  padding-top: 11px;
  padding-left: 10px;
 

}

.client-signup-container .form-group input:focus {
  background-color: transparent;
  /* color: white; */
  box-shadow: none;
  /* border: 1.5px solid white; */

}

.client-signup-container .client-pass input:focus {
  border: 1px solid gray;
}

.client-signup-container .form-group .select-menu {
  width: 100%;
  height: 45px;
  border-radius: 8px;
  background-color: transparent;
  /* border: 1.5px solid black; */
  color: gray;
  font-weight: 400;
  outline: none;
}

.client-signup-container .form-group option {
  background-color: transparent;
  color: gray;
  width: 50%;
}

small {
  color: red;
  font-size: smaller;
}

.terms-condition {
  color: #fff;
}

.eye-icon {
  font-size: 15px;

  /* color: #fff; */

}

.terms-check {
  display: flex;
  position: relative;
  grid-gap: 5px;
  gap: 5px;

}

.inp-check {
  width: 25px;
}



@media (max-width: 676px) {
  .client-signup-container {
    width: 100%;
    margin: auto;
    background-color: transparent;
    border: none;
  }

  .client-signup-container h3 {
    font-size: 25px;
    text-align: center;
  }

  .client-signup-container .blink img {
    width: 35%;
  }

  .sign-up,
  .log-in {
    display: none;
  }

  .form-group {
    margin: 0px !important;
  }

  .client-signup-container .first {
    margin-bottom: 4%;
  }
  .client-signup-container .email {
    margin-bottom: 4%;
  }
  .client-signup-container .password {
    margin-bottom: 4%;
  }
  .client-signup-container .form-group input{
    margin-top: 10px;
  
  }
  .floating-label{
    top: 20px;
  }
}

@media (min-width: 768px) {
  .client-signup-container .blink {
    display: none;

  }
}

@media (min-width: 768px) and (max-width: 1023px) {
  .client-signup-container {
    width: 90%;
  }

  .client-signup-container .client-container-heading {
    margin-top: 0px !important;
  }

  .client-signup-container .client-container-heading h3 {
    font-size: 22px;
  }
  label {
    
    left: 1px;}

}

@media (min-width: 1024px) and (max-width: 1439px) {
  .client-signup-container {
    width: 90%;

  }

  .client-container-heading h3 {
    font-size: 25px;
  }

  .client-signup-container .client-container-heading {
    margin-top: 1rem !important;
  }

  .client-signup-container .sign-up,
  .client-signup-container .log-in {
    width: 105px;
    font-size: 18px;
    padding: 4px;
  }
}

@media (min-width: 1440px) {
  .client-signup-container {
    width: 90%;
  }

  .client-container-heading h3 {
    font-size: 35px;
  }

  .client-signup-container .client-container-heading {
    margin-top: 1rem !important;
  }
}
.client-otp-container  {
    width: 95%;
    margin: auto;
    background-color: transparent;
    border: none;
    color: black;
    text-align: center;
    

}

.resend-btn-clicked {
    font-size: 16px;
    color: red; /* Adjust the font size as needed */
  }
.client-otp-heading h4 {
    font-weight: 500;
    font-size: 25px;
}
 
.client-otp-heading span {
    font-weight: 400;
    font-size: 19px;
}

.otp input {
    width: 42px;
    height: 42px;
    background-color: transparent;
    border: 2px solid #999;
    margin: 4px;
    font-size: 25px;
    color: black;
    outline: none;
    text-align: center;
    margin-top: 20px;
}

input::-webkit-outer-spin-button,
input::-webkit-inner-spin-button {
    -webkit-appearance: none;
    margin: 0;
}
.client-otp-container .resend-otp a{
    color:#283E9C;
    font-size: 18px;
}
.buttons button{

    font-weight: 700;
    font-size: 18px;
    width: 40%;
    height: 45px;
    color: white;
    background-color: #283E9C;
    border-radius: 8px;
    border: none;
   
}  
.resend-otp{
    text-align: center;
}
.resend-btn{
    background: transparent;
    border: none;
    color: white;
   
    
}
@media (max-width:376px){
    .client-otp-container{
        width: 90%;
    }
 
    .client-otp-heading h4 {
        font-size: 17px;
    }

    .client-otp-heading span {
        font-size: 14px;
    }
    .otp input {
        width: 35px;
        height: 35px;
    }
   
    
    .buttons .submit {
        font-size: 18px;
        width: 80%;
        height: 35px;   
    }
} 
@media (min-width:377px) and (max-width:767px){
    .client-otp-container{
        width: 95%;
    }



    .client-otp-heading h4 {
        font-size: 22px;
    }

    .client-otp-heading span {
        font-size: 17px;
    }
    .otp input {
        width: 40px;
        height: 40px;
       
    }
   
    .buttons .submit {
        font-size: 16px;
        width: 80%;

    }
} 
@media (min-width: 768px) and (max-width: 1023px) {
   
    .client-otp-container {
        width: 100%;
    }
    .client-otp-container  {
        position: relative;

        top: 24%;
    }

    .client-otp-heading h4 {
        font-size: 20px;
    }

    .client-otp-heading span {
        font-size: 15px;
    }

    .otp input {
        width: 36px;
        height: 36px;
    }
  
    .buttons .submit {
        font-size: 22px;
        width: 50%;
        height: 45px;

    }
}

@media (min-width: 1024px) and (max-width:1439px) {
      
    .client-otp-container  {
        position:relaive;
        top: 24%;
    }


 
    .client-otp-heading h4 {
        font-size: 24px;
    }

    .client-otp-heading span {
        font-size: 18px;
    }
  
    .otp input {
        width: 38px;
        height: 38px;
    }
    .buttons .submit {
        font-size: 23px;
        width: 60%;
        height: 45px;
    }

}

@media (min-width:1440px) and (max-width:1999px){
    
    .client-otp-container{
        width: 90%;
    }

    .client-otp-container  {
    
    top: 24%;
    width: 95%;
    }
 
    
    .client-otp-heading h4 {
        font-size: 25px;
    }
     
    .client-otp-heading span {
        font-size: 20px;
    }
    .otp input {
        width: 50px;
        height: 50px;
    }
   
    .client-otp-container .resend-otp a{
        font-size: 23px;
    }
    .buttons .submit {
        font-size: 20px;
        width: 45%;
        height: 45px;
    }
} 
@media (min-width:2000px){

    
    .client-otp-heading h4 {
        font-size: 37px;
    }
     
    .client-otp-heading span {
        font-size: 28px;
    }
    .otp input {
        width: 50px;
        height: 50px;   
    }
    
    .client-otp-container .resend-otp a{
        font-size: 18px;
    }
    .buttons button{
        font-size: 22px;
        width: 40%;
        height: 45px;
        
    } 
}

.input-container {
  position: relative;
 
  
}

.floating-label {
  position: absolute;
  top: 10px;
  left: 15px;
  font-size: 12px;
  color: #555; /* Change the color as needed */
  transition: top 0.3s, font-size 0.3s;
}
.user-url:focus + .floating-label,
.user-url:not(:placeholder-shown) + .floating-label {
  top: -10px;
  font-size: 10px;
  
  color: #007bff; /* Change the color when focused or has value */
}

.client-url-container {
  width: 95%;
  margin: auto;
  background-color: transparent;
  border: none;
 
  text-align: center;
}

.client-url-container {
  top: 22%;
}

.client-url-container .url-heading h2 {
  font-weight: 500;
  font-size: 30px;
  font-weight: 600;
  color: #262626;
}

.client-url-container .url-heading span {
  font-size: 22px;
  font-weight: 400;
}

.client-url-container .url-input {
  align-items: center;
  position: relative;
  margin: auto;
 
}

.client-url-container .url-input {
  text-align: center;
}
.client-url-container .url-input b {
  font-size: 18px;
}
.client-url-container .url-input input {
  border: 1.5px solid white;
  height: 45px;
  width:100%;
  border-radius: 8px;
  background-color: transparent;
  border: 2px solid #999;
  font-size: 18px;
  color: black;
}

.client-url-container .url-input ::placeholder {
  color: white;
  font-size: 18px;
}
.client-url-container .url-input .candidate{
  margin-top: 2%;
}
.client-url-container .url-input b {
  font-weight: 400;

  align-self: self-start;
}
.client-url-container .url-input span {
  font-size: 18px;
  font-weight: 400;
}

.client-url-container .sign-btn .btn {
  height: 45px;
  background-color: #283E9C;
  font-weight: 700;
  width: 80%;
  color: white !important;
  border-radius: 8px;
  font-size: 18px;
  border: none;
}
.client-url-container .url-input .url-notes {
  display: flex;
  flex-direction: column;
  margin:  auto;
  overflow: hidden;
  border-radius:10px;
  font-size: 30px;
  width: 70%;
  align-items: center;
  

  }


@media (max-width: 321px) {
  .client-url-container {
    width: 100%;
  }
  .client-url-container .url-heading h2 {
    font-size: 20px;
  }

  .client-url-container .url-heading span {
    font-size: 15px;
  }

  .client-url-container .url-input span {
    font-size: 18px !important;
    font-weight: 400;
  }

  .client-url-container .url-input input {
    width: 100%;
    font-size: 18px;
  }

  .client-url-container .sign-btn .btn {
    font-size: 18px;
    width: 100%;
    border: none;
  }
  .client-url-container .url-input .url-notes{
    font-size: 18px;
    text-align: left;
    margin:  auto;
    overflow: hidden;
    border-radius:10px;
    width: 100%;
  }
  .client-url-container .url-input .url-notes small{
    font-size: 18px;

  }
}

@media (min-width: 322px) and (max-width: 376px) {
  .client-url-container {
    width: 100%;
  }



  .client-url-container .url-heading h2 {
    font-size: 22px;
  }

  .client-url-container .url-heading span {
    font-size: 18px;
  }

  .client-url-container .url-input span {
    font-size: 15px;
  }

  .client-url-container .url-input input {
    border: 1.5px solid #999;
    height: 40px;
    width: 100%;
    font-size: 18px;
  }

  .client-url-container .sign-btn .btn {
    height: 40px;
    background-color: #283E9C;   
     font-size: 18px;
    width: 100%;
    border: none;
  }
  .client-url-container .url-input .url-notes{
    font-size: 18px;
    text-align: left;
    margin:  auto;
    overflow: hidden;
    border-radius:10px;
    width: 100%;
  }
  .client-url-container .url-input .url-notes small{
    font-size: 20px;

  }
}

@media (min-width: 377px) and (max-width: 767px) {
  .client-url-container {
    width: 100%;
  }



  .client-url-container .url-heading h2 {
    font-size: 25px;
  }

  .client-url-container .url-heading span {
    font-size: 20px;
  }

  .client-url-container .url-input span {
    font-size: 17px;
  }

  .client-url-container .url-input input {
    border: 1.5px solid #999;
    height: 40px;
    width: 100%;

    font-size: 18px;
  }

  .client-url-container .sign-btn .btn {
    height: 40px;
    background-color: #283E9C;
    font-weight: 700;
    font-size: 18px;
    width: 100%;
    border: none;
  }
  .client-url-container .url-input .url-notes{
    font-size: 18px;
    text-align: left;
    margin:  auto;
    overflow: hidden;
    border-radius:10px;
    width: 100%;
  }
  .client-url-container .url-input .url-notes small{
    font-size: 22px;

  }
  
}

@media (min-width: 768px) and (max-width: 1023px) {
 

  .client-url-container {
    width: 100%;
    margin-left: 20px;
  }

  .client-url-container .url-heading h2 {
    font-size: 28px;
  }

  .client-url-container .url-heading span {
    font-size: 16px;
  }

  .client-url-container .url-input span {
    font-size: 15px;
  }

  .client-url-container .url-input input {
    border: 2px solid #999;
    height: 40px;
    width: 95%;
    font-size: 18px;

  }

  .client-url-container .sign-btn .btn {
    height: 40px;
    width: 55%;
    background-color: #283E9C;
    font-size: 18px;
    border: none;
  }
  .client-url-container .url-input .url-notes{
    font-size: 18px;
    text-align: left;
    margin:  auto;
    overflow: hidden;
    border-radius:10px;
    width: 100%;
  }
  .client-url-container .url-input .url-notes small{
    font-size: 24px;

  }
}



@media (min-width: 1024px) and (max-width: 1199px) {
  .client-url-container {
    width: 100%;
  }

  .client-url-container .url-heading h2 {
    font-size: 25px;
  }

  .client-url-container .url-heading span {
    font-size: 19px;
  }

  .client-url-container .url-input input {
    width: 90%;
    align-items: center;
    margin: auto;

  }

  .client-url-container .url-input span {
    font-size: 17px;
  }

  .client-url-container .sign-btn .btn {
    height: 40px;
    width: 55%;
    margin: auto;
  }
  .client-url-container .url-input .url-notes{
    font-size: 18px;
    text-align: left;
    margin:  auto;
    overflow: hidden;
    border-radius:10px;
    width: 90%;
  }
  .client-url-container .url-input .url-notes small{
    font-size: 22px;

  }
}

@media (min-width: 1200px) {
  .client-url-container .url-heading h2 {
    font-size: 30px;
  }

  .client-url-container .url-heading span {
    font-size: 22px;
  }

  .client-url-container .url-input input {
    position: relative;
    margin: auto;
    width: 90%;
  }

  .client-url-container .url-input span {
    font-size: 19px;
  }

  .client-url-container .sign-btn .btn {
    height: 40px;
    width: 50%;
  }
  .client-url-container .url-input .url-notes{
    font-size: 18px;
    text-align: left;
    margin:  auto;
    overflow: hidden;
    border-radius:10px;
    width: 90%;
  }
  .client-url-container .url-input .url-notes small{
    font-size: 22px;

  }
}

@media (min-width: 2550px) {
  .client-url-container .url-input input {
    position: relative;
    margin: auto;
    width: 60%;
  }

  .client-url-container .url-input span {
    font-size: 20px;
  }

  .client-url-container .sign-btn .btn {
    height: 40px;
    width: 30%;
  }
  .client-url-container .url-input .url-notes{
    font-size: 18px;
    text-align: left;
    margin:  auto;
    overflow: hidden;
    border-radius:10px;
    width: 60%;
  }
  .client-url-container .url-input .url-notes small{
    font-size: 22px;

  }
}

.client-loading-bar-container  {
    position: relative;
    /*  property change from absolte to relative */
    top: 28%;
}

 .client-loading-bar-container{
    width: 80%;
    height: 60%;
    margin: auto;
    border: none;
    background-color: transparent;
    color: black;
    text-align: center;
}
 .client-loading-bar-container .loading-bar-heading h2{
    font-size: 32px;
    font-weight: 600;
}
 .client-loading-bar-container .loading-bar-heading span{
    font-size: 20px;
    color: black !important;
    font-weight: 400;
}
 .client-loading-bar-container .loading-bar progress {
    width:58%;
    height:35px;
    border-radius: 4px;
    border: 2px solid #ffffff;
    margin-top: 15px;
    text-align: center;
}
progress::-webkit-progress-bar {
    background-color: transparent;
}
progress::-webkit-progress-value {
    /* background-color: #ffcc00; */
    background-color: #283E9C;
    border-radius: 4px;
}
 .client-loading-bar-container .percentage-value {
    font-size: 20px;
    font-weight: 500;
}

 .client-loading-bar-container .loading-paragraph {
    font-size: 20px;
    font-weight: 700;
}


@media (max-width:376px){
     .client-loading-bar-container {
        width: 95%;
        height: 80%;
    }
     .client-loading-bar-container .blink-logo{
        text-align: left;
    }
     .client-loading-bar-container .loading-bar-heading h2{
        font-size: 20px;
    }

    .client-loading-bar-container .loading-bar-heading span{
        font-size: 18px;
    }
    .client-loading-bar-container .blink-logo img{
        width: 30%;
    }
    .client-loading-bar-container .percentage-value {
        font-size: 14px;
    }
    .client-loading-bar-container .loading-paragraph {
        font-size: 14px;
    }
    .client-loading-bar-container .loading-bar progress {
        width: 95%;
        height: 30px;
    }
}
@media (min-width:377px) and (max-width:767px){
     .client-loading-bar-container {
        width: 95%;
        height: 80%;
    }
     .client-loading-bar-container .blink-logo{
        text-align: left;
    }
     .client-loading-bar-container .blink-logo img{
        width: 25%;
    }
    .client-loading-bar-container .loading-bar-heading h2{
        font-size: 24px;
    } 
    .client-loading-bar-container .loading-bar-heading span{
        font-size: 20px;
    }   
    .client-loading-bar-container .percentage-value {
        font-size: 16px;
    }
    .client-loading-bar-container .loading-paragraph {
        font-size: 16px;
    }
    .client-loading-bar-container .loading-bar progress {
        width: 90%;
        height: 30px;
    }
}
@media (min-width:768px){
     .client-loading-bar-container .blink-logo{
        display: none;
    }
}
@media (min-width:768px) and (max-width:1023px){
     .client-loading-bar-container  {
        position: relative;
        top: 15%;
    }
     .client-loading-bar-container {
        width: 90%;
        height: 60%;
        margin-left: 30px;
    }    
     .client-loading-bar-container .loading-bar-heading h2{
        font-size: 27px;
    }

     .client-loading-bar-container .loading-bar-heading span{
        font-size: 17px;
        color: #EEEEEE;
    }

     .client-loading-bar-container .loading-bar progress {
        width:100%;
        height:30px;
        border-radius: 4px;
        border: 2px solid #ffffff;
        padding: 2px;
    }

     .client-loading-bar-container .loading-paragraph {
        font-size: 15px;
    }
    
     .client-loading-bar-container .percentage-value {
        font-size: 16px;
    }
}
@media (min-width:1024px) and (max-width:1439px){

    .client-loading-bar-container{
        width: 95%;
        margin-left: 20px;
    }

    .client-loading-bar-container .loading-bar-heading h2{
       font-size: 29px;
    }

    .client-loading-bar-container .loading-bar-heading span{
        font-size: 18px;
        color: #EEEEEE;
    }

    .client-loading-bar-container .loading-bar progress {
        width:95%;
        height:30px;
        border-radius: 4px;
        border: 2px solid #ffffff;
        padding: 2px;
        margin-top: 40px;
    }

    .client-loading-bar-container .percentage-value {
        font-size: 18px;
        font-weight: 500;
    }
    .client-loading-bar-container .loading-paragraph {
        font-size: 20px;
    }
}
@media (min-width:1440px) and (max-width:1799px){

    .client-loading-bar-container{
        width: 95%;
    }

    .client-loading-bar-container .loading-bar-heading h2{
       font-size: 30px;
    }

    .client-loading-bar-container .loading-bar-heading span{
        font-size: 18px;
        
    }

    .client-loading-bar-container .loading-bar progress {
        width:75%;
        height:30px;
        border-radius: 4px;
        border: 2px solid #ffffff;
        padding: 2px;
        margin-top: 15px;
    }

    .client-loading-bar-container .percentage-value {
        font-size: 18px;
        font-weight: 500;
    }
    .client-loading-bar-container .loading-paragraph {
        font-size: 20px;
    }
}
/* @media (min-width:1800px){
    .client-loading-bar-container .loading-bar-heading h2{
        font-size: 30px;
     }
 
     .client-loading-bar-container .loading-bar-heading span{
         font-size: 25px;
         color: #EEEEEE;
     }
 
     .client-loading-bar-container .loading-bar progress {
         width:50%;
         height:30px;
         border-radius: 4px;
         border: 2px solid #ffffff;
         padding: 2px;
         margin-top: 40px;
     }
 
     .client-loading-bar-container .percentage-value {
         font-size: 22px;
         font-weight: 500;
     }
     .client-loading-bar-container .loading-paragraph {
         font-size: 22px;
     }
} */
.client-otp-container  {
    width: 95%;
    margin: auto;
    background-color: transparent;
    border: none;
    color: black;
    text-align: center;
    

}

.resend-btn-clicked {
    font-size: 16px;
    color: red; /* Adjust the font size as needed */
  }
.client-otp-heading h4 {
    font-weight: 500;
    font-size: 25px;
}
 
.client-otp-heading span {
    font-weight: 400;
    font-size: 19px;
}

.otp input {
    width: 42px;
    height: 42px;
    background-color: transparent;
    border: 2px solid #999;
    margin: 4px;
    font-size: 25px;
    color: black;
    outline: none;
    text-align: center;
    margin-top: 20px;
}

input::-webkit-outer-spin-button,
input::-webkit-inner-spin-button {
    -webkit-appearance: none;
    margin: 0;
}
.client-otp-container .resend-otp a{
    color:#283E9C;
    font-size: 18px;
}
.buttons button{

    font-weight: 700;
    font-size: 18px;
    width: 85%;
    height: 45px;
    color: white;
    background-color: #F36626;
    border-radius: 8px;
    border: none;
   
}  
.resend-otp{
    text-align: center;
}
.resend-btn{
    background: transparent;
    border: none;
    color: white;
   
    
}
@media (max-width:376px){
    .client-otp-container{
        width: 90%;
    }
 
    .client-otp-heading h4 {
        font-size: 17px;
    }

    .client-otp-heading span {
        font-size: 14px;
    }
    .otp input {
        width: 35px;
        height: 35px;
    }
   
    
    .buttons .submit {
        font-size: 15px;
        width: 90%;
        height: 35px;
    
    }
    .client-otp-container .radioLabel {
        font-Size: 12px;
    }
} 
@media (min-width:377px) and (max-width:767px){
    .client-otp-container{
        width: 95%;
    }



    .client-otp-heading h4 {
        font-size: 20px;
    }

    .client-otp-heading span {
        font-size: 15px;
    }
    .otp input {
        width: 35px;
        height: 35px;
       
    }
   
    .buttons .submit {
        font-size: 16px;
        width: 80%;

    }
} 
@media (min-width: 768px) and (max-width: 1023px) {
   
    .client-otp-container {
        width: 100%;
    }
    .client-otp-container  {
        position: relative;

        top: 24%;
    }

    .client-otp-heading h4 {
        font-size: 20px;
    }

    .client-otp-heading span {
        font-size: 15px;
    }

    .otp input {
        width: 35px;
        height: 35px;
    }
  
    .buttons .submit {
        font-size: 22px;
        width: 90%;
        height: 35px;
        font-size: 16px;

    }
}

@media (min-width: 1024px) and (max-width:1439px) {
      
    .client-otp-container  {
        position:relaive;
        top: 24%;
    }


 
    .client-otp-heading h4 {
        font-size: 24px;
    }

    .client-otp-heading span {
        font-size: 18px;
    }
  
    .otp input {
        width: 36px;
        height: 36px;
    }
    .buttons .submit {
        font-size: 23px;
        width: 60%;
        height: 45px;
        font-size: 18px;
    }

}

@media (min-width:1440px) and (max-width:1999px){
    
    .client-otp-container{
        width: 90%;
    }

    .client-otp-container  {
    
    top: 24%;
    width: 95%;
    }
 
    
    .client-otp-heading h4 {
        font-size: 25px;
    }
     
    .client-otp-heading span {
        font-size: 20px;
    }
    .otp input {
        width: 45px;
        height: 45px;
    }
   
    .client-otp-container .resend-otp a{
        font-size: 18px;
    }
    .buttons .submit {
        font-size: 20px;
        width: 85%;
        height: 45px;
    }
} 
@media (min-width:2000px){

    
    .client-otp-heading h4 {
        font-size: 37px;
    }
     
    .client-otp-heading span {
        font-size: 28px;
    }
    .otp input {
        width: 50px;
        height: 50px;   
    }
    
    .client-otp-container .resend-otp a{
        font-size: 18px;
    }
    .buttons button{
        font-size: 22px;
        width: 40%;
        height: 45px;
        
    } 
}

.input-container {
  position: relative;
 
  
}

.floating-label {
  position: absolute;
  top: 10px;
  left: 15px;
  font-size: 12px;
  color: #555; /* Change the color as needed */
  transition: top 0.3s, font-size 0.3s;
}
.user-url:focus + .floating-label,
.user-url:not(:placeholder-shown) + .floating-label {
  top: -10px;
  font-size: 10px;
  
  color: #007bff; /* Change the color when focused or has value */
}

.client-url-container {
  width: 95%;
  margin: auto;
  background-color: transparent;
  border: none;
 
  text-align: center;
}

.client-url-container {
  top: 22%;
}

.client-url-container .url-heading h2 {
  font-weight: 500;
  font-size: 25px;
  font-weight: 600;
  color: #262626;
}

.client-url-container .url-heading span {
  font-size: 22px;
  font-weight: 400;
}

.client-url-container .url-input {
  align-items: center;
  position: relative;
  margin: auto;
 
}

.client-url-container .url-input {
  text-align: center;
}
.client-url-container .url-input b {
  font-size: 18px;
}
.client-url-container .url-input input {
  border: 1.5px solid white;
  height: 40px;
  width:100%;
  border-radius: 20px;
  background-color: transparent;
  border: 1px solid #8C8C8C;
  font-size: 18px;
  color: black;
}

.client-url-container .url-input ::placeholder {
  color: white;
  font-size: 18px;
}
.client-url-container .url-input .candidate{
  margin-top: 2%;
}
.client-url-container .url-input b {
  font-weight: 400;

  align-self: self-start;
}
.client-url-container .url-input span {
  font-size: 18px;
  font-weight: 400;
  
}

.client-url-container .sign-btn .btn {
  height: 40px;
  background-color: #F36626;
  font-weight: 700;
  width: 100%;
  color: white !important;
  border-radius: 12px;
  font-size: 18px;
  border: none;
}
.client-url-container .url-input .url-notes {
  display: flex;
  flex-direction: column;
  margin:  auto;
  overflow: hidden;
  border-radius:10px;
  font-size: 30px;
  width: 70%;
  align-items: center;
  

  }


@media (max-width: 321px) {
  .client-url-container {
    width: 100%;
  }
  .client-url-container .url-heading h2 {
    font-size: 20px;
  }

  .client-url-container .url-heading span {
    font-size: 15px;
  }

  .client-url-container .url-input span {
    font-size: 11px !important;
    font-weight: 400;
  }

  .client-url-container .url-input input {
   height: 35px;
  }

  .client-url-container .sign-btn .btn {
    font-size: 18px;
    height: 35px;
  }
  .client-url-container .url-input .url-notes{
    font-size: 18px;
    text-align: left;
    margin:  auto;
    overflow: hidden;
    border-radius:10px;
    width: 100%;
    
  }
  .client-url-container .url-input .url-notes small{
    font-size: 16px;
    color: #283E9C !important; 

  }
}

@media (min-width: 322px) and (max-width: 376px) {
  .client-url-container {
    width: 100%;
  }
  .client-url-container .url-heading h2 {
    font-size: 20px;
  }

  .client-url-container .url-heading span {
    font-size: 18px;
  }

  .client-url-container .url-input span {
    font-size: 11px !important;
  }

  .client-url-container .url-input input {  
    height: 35px;
   
  }

  .client-url-container .sign-btn .btn {
    height: 35px;
  }
  .client-url-container .url-input .url-notes{
    font-size: 18px;
    text-align: left;
    margin:  auto;
    overflow: hidden;
    border-radius:10px;
    width: 100%;
  }
  .client-url-container .url-input .url-notes small{
    font-size: 18px;

  }

}

@media (min-width: 377px) and (max-width: 767px) {
  .client-url-container {
    width: 100%;
  }

  .client-url-container .url-heading h2 {
    font-size: 22px;
  }

  .client-url-container .url-heading span {
    font-size: 20px;
  }

  .client-url-container .url-input span {
    font-size: 17px;
  }

  .client-url-container .url-input input {
    height: 40px;
    padding-left:15px  !important;
    font-size: 16px;

  }

  .client-url-container .sign-btn .btn {
    height: 40px;
  }
  .client-url-container .url-input .url-notes{
    font-size: 18px;
    text-align: left;
    margin:  auto;
    overflow: hidden;
    border-radius:10px;
    width: 100%;
  }
  .client-url-container .url-input .url-notes small{
    font-size: 18px;
  }
  .client-url-container .url-input span {
    font-size: 13px !important;
}

  
}

@media (min-width: 768px) and (max-width: 1023px) {
 

  .client-url-container {
    width: 100
  }

  .client-url-container .url-heading h2 {
    font-size: 18px;
  }

  .client-url-container .url-heading span {
    font-size: 16px;
  }

  .client-url-container .url-input span {
    font-size: 12px !important;
  }

  .client-url-container .url-input input {
    height: 35px;

    font-size: 18px;
  }

  .client-url-container .sign-btn .btn {
    height: 35px;
    font-size: 16px;
  }
  .client-url-container .url-input .url-notes{
    font-size: 18px;
    text-align: left;
    margin:  auto;
    overflow: hidden;
    border-radius:10px;
    width: 100%;
  }
  .client-url-container .url-input .url-notes small{
    font-size: 18px;

  }
}



@media (min-width: 1024px) and (max-width: 1199px) {
  .client-url-container {
    width: 100%;
  }

  .client-url-container .url-heading h2 {
    font-size: 25px;
  }

  .client-url-container .url-heading span {
    font-size: 19px;
  }

  .client-url-container .url-input input {
   height: 40px;

  }

  .client-url-container .url-input span {
    font-size: 15px !important;
  }

  .client-url-container .sign-btn .btn {
    height: 40px;
    width: 100%;
    
  }
  .client-url-container .url-input .url-notes{
    font-size: 18px;
    text-align: left;
    margin:  auto;
    overflow: hidden;
    border-radius:10px;
    width: 90%;
  }
  .client-url-container .url-input .url-notes small{
    font-size: 20px;

  }
}

@media (min-width: 1200px) {
  .client-url-container .url-heading h2 {
    font-size: 25px;
  }

  .client-url-container .url-heading span {
    font-size: 22px;
  }

  .client-url-container .url-input input {
    position: relative;
    margin: auto;
   
  }

  .client-url-container .url-input span {
    font-size: 15px ;
  }

  .client-url-container .sign-btn .btn {
    height: 40px;
    
  }
  .client-url-container .url-input .url-notes{
    font-size: 18px;
    text-align: left;
    margin:  auto;
    overflow: hidden;
    border-radius:10px;
    width: 90%;
  }
  .client-url-container .url-input .url-notes small{
    font-size: 22px;

  }
}

@media (min-width: 2550px) {
  .client-url-container .url-input input {
    position: relative;
    margin: auto;
    width: 60%;
  }

  .client-url-container .url-input span {
    font-size: 20px !important;
  }

  .client-url-container .sign-btn .btn {
    height: 40px;
    width: 30%;
  }
  .client-url-container .url-input .url-notes{
    font-size: 18px;
    text-align: left;
    margin:  auto;
    overflow: hidden;
    border-radius:10px;
    width: 60%;
  }
  .client-url-container .url-input .url-notes small{
    font-size: 22px;

  }
}

.client-loading-bar-container  {
    position: relative;
    /*  property change from absolte to relative */
    top: 28%;
}

 .client-loading-bar-container{
    width: 80%;
    height: 60%;
    margin: auto;
    border: none;
    background-color: transparent;
    color: black;
    text-align: center;
}
 .client-loading-bar-container .loading-bar-heading h2{
    font-size: 30px;
    font-weight: 600;
}
 .client-loading-bar-container .loading-bar-heading span{
    font-size: 20px;
    color: black !important;
    font-weight: 400;
}
 .client-loading-bar-container .loading-bar progress {
    width:58%;
    height:35px;
    border-radius: 20px;
    border: 2px solid #ffffff;
    margin-top: 15px;
    text-align: center;
}
progress::-webkit-progress-bar {
    background-color: transparent;
}
progress::-webkit-progress-value {
    /* background-color: #ffcc00; */
    background-color: #283E9C;
    border-radius: 4px;
}
 .client-loading-bar-container .percentage-value {
    font-size: 20px;
    font-weight: 500;
}

 .client-loading-bar-container .loading-paragraph {
    font-size: 20px;
    font-weight: 700;
}


@media (max-width:376px){
     .client-loading-bar-container {
        width: 95%;
        height: 80%;
    }
     .client-loading-bar-container .blink-logo{
        text-align: left;
    }
     .client-loading-bar-container .loading-bar-heading h2{
        font-size: 20px;
    }

    .client-loading-bar-container .loading-bar-heading span{
        font-size: 18px;
    }
    .client-loading-bar-container .blink-logo img{
        width: 30%;
    }
    .client-loading-bar-container .percentage-value {
        font-size: 14px;
    }
    .client-loading-bar-container .loading-paragraph {
        font-size: 14px;
    }
    .client-loading-bar-container .loading-bar progress {
        width: 95%;
        height: 30px;
    }
}
@media (min-width:377px) and (max-width:767px){
     .client-loading-bar-container {
        width: 95%;
        height: 80%;
    }
     .client-loading-bar-container .blink-logo{
        text-align: left;
    }
     .client-loading-bar-container .blink-logo img{
        width: 25%;
    }
    .client-loading-bar-container .loading-bar-heading h2{
        font-size: 24px;
    } 
    .client-loading-bar-container .loading-bar-heading span{
        font-size: 20px;
    }   
    .client-loading-bar-container .percentage-value {
        font-size: 16px;
    }
    .client-loading-bar-container .loading-paragraph {
        font-size: 16px;
    }
    .client-loading-bar-container .loading-bar progress {
        width: 90%;
        height: 30px;
    }
}
@media (min-width:768px){
     .client-loading-bar-container .blink-logo{
        display: none;
    }
}
@media (min-width:768px) and (max-width:1023px){
     .client-loading-bar-container  {
        position: relative;
        top: 15%;
    }
     .client-loading-bar-container {
        width: 90%;
        height: 60%;
        margin-left: 30px;
    }    
     .client-loading-bar-container .loading-bar-heading h2{
        font-size: 25px;
    }

     .client-loading-bar-container .loading-bar-heading span{
        font-size: 17px;
        color: #EEEEEE;
    }

     .client-loading-bar-container .loading-bar progress {
        width:100%;
        height:30px;
        border-radius: 4px;
        border: 2px solid #ffffff;
        padding: 2px;
    }

     .client-loading-bar-container .loading-paragraph {
        font-size: 15px;
    }
    
     .client-loading-bar-container .percentage-value {
        font-size: 16px;
    }
}
@media (min-width:1024px) and (max-width:1439px){

    .client-loading-bar-container{
        width: 95%;
        margin-left: 20px;
    }

    .client-loading-bar-container .loading-bar-heading h2{
       font-size: 28px;
    }

    .client-loading-bar-container .loading-bar-heading span{
        font-size: 18px;
        color: #EEEEEE;
    }

    .client-loading-bar-container .loading-bar progress {
        width:95%;
        height:30px;
        border-radius: 4px;
        border: 2px solid #ffffff;
        padding: 2px;
        margin-top: 40px;
    }

    .client-loading-bar-container .percentage-value {
        font-size: 18px;
        font-weight: 500;
    }
    .client-loading-bar-container .loading-paragraph {
        font-size: 20px;
    }
}
@media (min-width:1440px) and (max-width:1799px){

    .client-loading-bar-container{
        width: 95%;
    }

    .client-loading-bar-container .loading-bar-heading h2{
       font-size: 26px;
    }

    .client-loading-bar-container .loading-bar-heading span{
        font-size: 16px;
        
    }

    .client-loading-bar-container .loading-bar progress {
        width:90%;
        height:30px;
        border-radius: 4px;
        border: 2px solid #ffffff;
        padding: 2px;
        margin-top: 15px;
    }

    .client-loading-bar-container .percentage-value {
        font-size: 18px;
        font-weight: 500;
    }
    .client-loading-bar-container .loading-paragraph {
        font-size: 20px;
    }
}
/* @media (min-width:1800px){
    .client-loading-bar-container .loading-bar-heading h2{
        font-size: 30px;
     }
 
     .client-loading-bar-container .loading-bar-heading span{
         font-size: 25px;
         color: #EEEEEE;
     }
 
     .client-loading-bar-container .loading-bar progress {
         width:50%;
         height:30px;
         border-radius: 4px;
         border: 2px solid #ffffff;
         padding: 2px;
         margin-top: 40px;
     }
 
     .client-loading-bar-container .percentage-value {
         font-size: 22px;
         font-weight: 500;
     }
     .client-loading-bar-container .loading-paragraph {
         font-size: 22px;
     }
} */
/* combine css for all templates fisrt container */

* {
  margin: 0;
  padding: 0;
  font-family: poppins;
}

.app-login-new-container {
  width: 85%;
  margin: auto auto;
  max-height: 100vh;
}

.app-login-new-container .welcome-container {
  color: black;
  border-radius: 12px;
  height: 95%;
  width: 100%;
  margin: auto;
  text-align: center;
}

.app-login-new-container .welcome-container .blink-logo img {
  width: 40%;
  /* float: left; */
}

.app-login-new-container .welcome-heading {
  text-align: center;
}

/* .app-login-new-container .welcome-heading h1 {
  font-weight: 700;
  font-size: 35px;
} */

.app-login-new-container .welcome-heading p {
  font-weight: 450;
  font-size: 21px;
  margin: 0;

}

.app-login-new-container .slider-heading {
  font-weight: 700;
  font-size: 35px;
}

@media (max-width: 767px) {
  body {

    background-repeat: no-repeat;
    background-size: 100% 100%;
    background-position: center;
    background-attachment: fixed;
    color: white;
  }
}

@media (min-width: 768px) {
  body {
    background-image: linear-gradient(45deg,
        rgba(12, 12, 12, 0.705),
        rgb(3, 43, 84));

    background-repeat: no-repeat;
    background-size: 100% 100%;
    background-position: center;
    background-attachment: fixed;
    color: white;
  }
}

.app-login-new-container .welcome-container {
  color: black;
  border-radius: 12px;
  height: 95%;
  width: 100%;
  margin: auto;
  text-align: center;
}

@media (min-width: 768px) and (max-width: 1023px) {
  /* .app-login-new-container .welcome-container {
    height: 100%;
    width: 100%;
    margin: auto;
  } */

  .app-login-new-container .welcome-container .welcome-heading h1 {
    font-size: 30px;
    margin-top: 30px;
  }

  .app-login-new-container .welcome-container .welcome-heading p {
    font-size: 17px;
    margin-top: 20px;
  }

  .welcome-container .blink-logo img {
    width: 25%;
  }

  .welcome-container .computer-img {
    margin-top: 50px;
  }
}

@media (min-width: 1024px) and (max-width: 1440px) {
  /* .app-login-new-container .welcome-container {
    width: 90%;
    height: 95vh;
  } */

  .app-login-new-container .welcome-heading h1 {
    font-size: 35px;
  }

  .app-login-new-container .welcome-container .welcome-heading p {
    font-size: 20px;
  }

  .welcome-container .blink-logo img {
    width: 25%;
  }

  .welcome-container .computer-img {
    margin-top: 50px;
  }
}

@media (min-width: 1440px) and (max-width: 2000px) {
  /* .app-login-new-container .welcome-container {
    height: 95vh;
    width: 80%;
  } */

  .app-login-new-container .welcome-heading h1 {
    font-size: 40px;
  }

  .app-login-new-container .welcome-container .welcome-heading p {
    font-size: 22px;
  }

  .welcome-container .blink-logo img {
    width: 25%;
  }

  .welcome-container .computer-img {
    margin-top: 30px;
  }
}

@media (min-width: 1800px) {
  /* .app-login-new-container .welcome-container {
    height: 100%;
    width: 80%;
  } */

  .app-login-new-container .welcome-heading h1 {
    font-size: 40px;
  }

  .app-login-new-container .welcome-container .welcome-heading p {
    font-size: 22px;
  }

  .welcome-container .blink-logo img {
    width: 20%;
  }
}
* {
    margin: 0;
    padding: 0;
}


.app-login-new-container {
    height: 100vh;

}

.app-login-new-container .fab-container {
    bottom: 0vh;
    position: fixed;
    margin: 1em;
    left: 2vw;
    right: 100vw;
}
.app-login-new-container .card-data-container{
     margin: auto;
     height:auto !important;
     width:80% !important;
     padding: 20px;
    }
    @media (max-width: 767px) {
        .app-login-new-container{
            margin-top: 15px;

            
        }
        .app-login-new-container .card-data-container{
            margin: auto;
            height:auto !important;
            width:100% !important;
          
           }
    }
    @media (min-width: 768px) and (max-width: 1023px) {
        .app-login-new-container .card-data-container{
            height: auto !important;
            width: 100% !important;
            padding: 10px 20px 10px 20px
        }
    }
    @media (min-width: 1024px) and (max-width: 1439px) {
        .app-login-new-container .card-data-container{
            height: auto !important;
            width: 100% !important;
        }
    }   

.form-group {
  position: relative;

}

label {
  position: absolute;
  top: 45%;
  left: 8px;
  transform: translateY(-40%);
  transition: transform 0.5s ease-out, font-size 0.5s ease-out;
  pointer-events: none;
  font-size: 16px;
  color: #999;
  padding: 7px;
  padding-left: 10px;
  background-color: white;
  border-radius: 4px;
  display: inline-block;
}

.floating-label {
  transform: translateY(-90%) scale(0.9);
  font-size: 16px !important;
  color: #283E9C !important;
  left: 8px !important;
  display: inline-block;
}

.input-icon-container {
  position: relative;
}

.form-group .phone-input {
  width: '100%';
  padding-Left: 16%
}

.eye-icon {
  position: absolute;
  top: 50%;
  right: 10px;
  transform: translateY(-50%);
  cursor: pointer;
  font-size: 22px !important;
}



.otp-Choice-container  .heading {
  font-size: 14px;
}

.radioGroup {
  display: flex;
}

 .radioButton {
  display: flex ;
  flex-Direction: row ;
  align-Items: center ;
}

 .radioLabel {
  margin-Left: 8px;
  font-Size: 13px;
  color: #333;
  margin-right: 20px;
  margin-bottom: 0px;
}



.client-signup-container {
  min-height: 70%;
  width: 85%;
  margin: auto;
  background-color: transparent;
  border: none;
}

.client-signup-container {
  position: relative;
  /* top: 10%; */
}

.client-signup-container .sign-up {
  background-color: transparent;
  border: 1.5px solid white;
  color: white;
}



.client-container-heading h3 {
  font-size: 30px;
  font-weight: 700;
  /* color: white; */
}

.client-signup-container .client-container-text p {
  font-size: 13px;
  font-weight: 400;
  color: #6c6c6c;
}

.client-signup-container .client-container-bottom-text p {
  font-size: 12px;
  font-weight: 400;
  color: #262626;
  text-align: justify;
}



.client-signup-container .Signup {
  background-color: #F36626;
  font-weight: 600;
  border-radius: 8px;

  font-size: 15px;
  height: 40px;
  color: white;
  border: none;
  width: '100%'

}

.client-signup-container .form-group input::-webkit-outer-spin-button,
input::-webkit-inner-spin-button {
  -webkit-appearance: none;
  margin: 0;
}

.client-signup-container .client-pass {
  width: 100%;
  /* border: 1.5px solid white; */
  border-radius: 8px;
}

.client-signup-container .form-group label {
  font-size: 14px;
}

.client-signup-container .form-group input {
  background-color: transparent;
  /* color: white; */
  font-weight: 400;
  /* border: 1 solid white; */
  border-radius: 8px;
  height: 40px;
  width: 100%;
  outline: none;
  padding-top: 11px;
  padding-left: 10px;



}

.client-signup-container .form-group input:focus {
  background-color: transparent;
  /* color: white; */
  box-shadow: none;
  /* border: 1.5px solid white; */

}

.client-signup-container .client-pass input:focus {
  border: 1px solid gray;
}

.client-signup-container .form-group .select-menu {
  width: 100%;
  height: 40px;
  border-radius: 8px;
  background-color: transparent;
  /* border: 1.5px solid black; */
  color: gray;
  font-weight: 400;
  outline: none;
  font-size: 14px;
}

.client-signup-container .form-group option {
  background-color: transparent;
  color: gray;
  width: 50%;
  font-size: 14px;
}

small {
  color: red;
  font-size: smaller;
}

.terms-condition {
  color: #fff;
}

.eye-icon {
  font-size: 15px;

  /* color: #fff; */

}

.terms-check {
  display: flex;
  position: relative;
  grid-gap: 5px;
  gap: 5px;

}

.terms-check .text-field {
  font-size: 14px;
}

.inp-check {
  width: 25px;
}




@media (max-width: 676px) {
  .client-signup-container {
    width: 100%;
    margin: auto;
    background-color: transparent;
    border: none;
  }

  .client-signup-container h3 {
    font-size: 18px;
    text-align: center;
  }

  .client-signup-container .blink img {
    width: 35%;
  }

  .sign-up,
  .log-in {
    display: none;
  }

  .form-group {
    margin: 0px !important;
  }

  .client-signup-container .first {
    margin-bottom: 3%;
  }

  .client-signup-container .email {
    margin-bottom: 3%;
  }

  .client-signup-container .password {
    margin-bottom: 3%;
  }

  .client-signup-container .form-group input {
    margin-top: 10px !important;
    font-size: 12px;

  }

  .floating-label {
    top: 10px;
  }

  .terms-check .text-field {
    font-size: 10px;
  }

  .client-signup-container .client-container-bottom-text p {
    font-size: 8px !important;
  }

  .client-signup-container .client-container-text p {
    font-size: 9px !important;
    text-align: center;
  }

  .client-signup-container .form-group label {
    font-size: 10px;
  }

  .client-signup-container .form-group input {
    height: 35px;

  }

  .form-group .first {
    margin-top: -10px !important;
  }

  .client-signup-container .form-group .select-menu {
    height: 35px;
    font-size: 10px;
  }

  .client-signup-container {
    margin-top: -10px;
  }

  .client-signup-container .Signup {
    height: 35px;
  }
  /* .organization{
    width: 90% !important;
    height: 35px !important;
    margin-left: 5% !important;
    font-size: 10px !important;
  }
  .student{
    width: 90% !important;
    height: 35px !important;
    margin-left: 5% !important;
    font-size: 10px !important;
    margin-top: 5% !important;
  } */
  .organizationText{
    font-size: 12px !important;
  }
  .restrictedText{
    font-size: 9px !important;
    padding: 10px !important;

  }
  .restrictedImg{
    height: 200px;
    width: 200px;
  }
}

@media screen and (min-width: 374px) and (max-width: 423px) {
  .form-group .phone-input {
    padding-left: 13% !important;
  }
}

@media screen and (min-width: 424px) and (max-width: 767px) {
  .form-group .phone-input {
    padding-left: 11% !important;
  }
}

@media (max-width:373px) {
  .form-group .phone-input {

    padding-Left: 15% !important
  }
}



@media (min-width: 768px) {
  .client-signup-container .blink {
    display: none;

  }
}

@media (min-width: 768px) and (max-width: 1023px) {
  .client-signup-container {
    width: 100%;
  }

  .client-signup-container .client-container-heading {
    margin-top: 0px !important;
  }

  .client-signup-container .client-container-heading h3 {
    font-size: 18px;
  }

  label {
    left: 8px;
  }

  .client-signup-container .client-container-text p {
    font-size: 10px;

  }

  .terms-check .text-field {
    font-size: 10px;
  }

  .client-signup-container .form-group .select-menu {
    font-size: 13px;
    height: 35px;
  }

  .client-signup-container .form-group label {
    font-size: 13px !important;
  }

  .client-signup-container .Signup {
    height: 35px;
  }

  .client-signup-container .form-group input {
    height: 35px;
    font-size: 12px;
  }

  .form-group .phone-input {
    padding-Left: 27% !important
  }
  .organizationText{
    font-size: 10px !important;
  }
  .restrictedText{
    font-size: 11px !important;
    padding: 10px !important;

  }
  .orgRadioButton{
    height: 35px !important;
  }

}

@media (min-width: 1024px) and (max-width: 1439px) {
  .client-signup-container {
    width: 100%;

  }

  .client-signup-container .form-group input {

    font-size: 12px !important;

  }

  .client-signup-container .form-group label {
    font-size: 15px;
  }

  .client-container-heading h3 {
    font-size: 18px;
  }

  .client-signup-container .client-container-text p {
    font-size: 11px;

  }

  .client-signup-container .client-container-heading {
    margin-top: 0rem !important;
  }

  .terms-check .text-field {
    font-size: 12px;
  }

  .client-signup-container .client-container-bottom-text p {
    font-size: 9px;
  }

  .client-signup-container .sign-up,
  .client-signup-container .log-in {
    width: 105px;
    font-size: 18px;
    padding: 4px;
  }

  label {
    font-size: 12px !important;
  }

  .floating-label {
    font-size: 16px !important;
  }

  .client-signup-container .form-group .select-menu {
    font-size: 12px;
  }

  .form-group .phone-input {
    padding-Left: 21% !important
  }
  .organizationText{
    font-size: 12px !important;
  }
}


@media (min-width: 1439px) {
  .client-signup-container {
    width: 100% !important;
  }

  .client-container-heading h3 {
    font-size: 22px;
    /* text-align: center; */
  }

  .client-signup-container .client-container-heading {
    margin-top: 0rem !important;
  }

  .client-signup-container .client-container-text p {
    font-size: 14px;
  }

  .client-signup-container .form-group .select-menu {
    font-size: 14px;
  }

  .form-group .phone-input {
    padding-Left: 18% !important
  }

}

@media (min-width: 2000px) {
  .form-group .phone-input {
    padding-Left: 10% !important
  }
  .client-container-heading h3 {
    font-size: 30px;
  }
  .client-signup-container .client-container-text p {
    font-size: 22px;
}
  .app-login-new-container .card-data-container {
    margin: auto;
    padding: 40px;
    width: 100% !important;
  }
  .client-signup-container .form-group label {
    font-size: 18px !important;
}
.client-signup-container .form-group .select-menu {
  font-size: 18px;
}
.client-signup-container .client-container-bottom-text p {
  font-size: 14px;}
  .terms-check .text-field {
    font-size: 16px;
}
.otp-Choice-container .heading {
  font-size: 18px;
}
.radioLabel {
  font-size: 16px;
}
.client-signup-container .Signup{
  font-size: 20px;
}
.organizationText{
  font-size: 15px !important;
}
  
}
